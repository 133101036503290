import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Custom Component Style" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "custom-components-style"
      }}>{`Custom components style`}</h2>
      <p>{`You have two ways to edit Pal.js UI components`}</p>
      <h4 {...{
        "id": "first"
      }}>{`First`}</h4>
      <p>{`Edit theme keys
every component have variables you can get it from component page theme tab and just pass it like we talk here `}<a parentName="p" {...{
          "href": "/guides/enable-theme-system"
        }}>{`Enable Theme System`}</a></p>
      <h4 {...{
        "id": "second"
      }}>{`Second`}</h4>
      <p>{`Use `}<a parentName="p" {...{
          "href": "https://www.styled-components.com/docs"
        }}>{`styled-components`}</a>{` package to do this simply too see example:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` styled `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'styled-components'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` Sidebar `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/ui/Sidebar'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// import original component`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// then pass here`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` SidebarStyled `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`styled`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`Sidebar`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`
  background: transparent;
  & header {
    padding-bottom: 0.5rem;
    text-align: center;
    display: flex;
    height: auto;
    button {
      font-size: 1.5rem;
      padding: 0.25rem 0.5rem;
    }
  }
`}</span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`//export styled one and use it`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` SidebarStyled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h4 {...{
        "id": "use-theme-variable"
      }}>{`Use Theme Variable`}</h4>
      <p>{`In case when you need to access theme variables from your application component`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` css `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'styled-components'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` newComponent `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`div`}<span parentName="code" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`
  `}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "token parameter"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`{`}</span>{` theme `}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` css`}<span parentName="span" {...{
                  "className": "token template-string"
                }}><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`
    .my-text {
      font-family: `}</span><span parentName="span" {...{
                    "className": "token interpolation"
                  }}><span parentName="span" {...{
                      "className": "token interpolation-punctuation punctuation"
                    }}>{`\${`}</span>{`theme`}<span parentName="span" {...{
                      "className": "token punctuation"
                    }}>{`.`}</span>{`fontFamilyPrimary`}<span parentName="span" {...{
                      "className": "token interpolation-punctuation punctuation"
                    }}>{`}`}</span></span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`;
      color: `}</span><span parentName="span" {...{
                    "className": "token interpolation"
                  }}><span parentName="span" {...{
                      "className": "token interpolation-punctuation punctuation"
                    }}>{`\${`}</span>{`theme`}<span parentName="span" {...{
                      "className": "token punctuation"
                    }}>{`.`}</span>{`textBasicColor`}<span parentName="span" {...{
                      "className": "token interpolation-punctuation punctuation"
                    }}>{`}`}</span></span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`;
    }
  `}</span><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span></span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token string"
              }}>{`
`}</span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      